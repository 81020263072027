import { useStore } from 'vuex'
import { ref, onBeforeUnmount, computed } from 'vue'

export default (props) => {

  const isPolling = ref(false)

  let poll = null
  let timeoutId = null
  let numDetailsOpen = 0
  let wasPollingWhenVisibilityStateHidden = false

  let currentPageHash = null
  let previousPageHash = null

  let pollingBackoffCount = 1
  let previousBackoffDirection = null

  const minPollingInterval = 2000
  const maxPollingInterval = 6000
  const pollingIntervalIncrement = 250
  const startPollingInterval = minPollingInterval
  let currentPollingInterval = props.startPollingInterval || startPollingInterval

  const store = useStore()

  // computed
  const paginationList = computed(() => {
    return store.state.pagination[props.paginationListName.value]
  })

  // methods
  const stopPolling = () => {
    isPolling.value = false
    clearTimeout(timeoutId)
  }

  const startPolling = () => {

    const currentBackoffDirection = currentPageHash !== previousPageHash
      ? 'down'
      : 'up'

    if (currentBackoffDirection === 'down') {
      currentPollingInterval = previousBackoffDirection === 'up'
        ? currentPollingInterval - pollingIntervalIncrement * pollingBackoffCount
        : minPollingInterval
    } else {
      currentPollingInterval += pollingIntervalIncrement * pollingBackoffCount
    }

    pollingBackoffCount += 1
    previousBackoffDirection = currentBackoffDirection

    if (
      currentPollingInterval <= minPollingInterval
      || currentPollingInterval >= maxPollingInterval
    ) {
      pollingBackoffCount = 1
      currentPollingInterval = Math.min(maxPollingInterval, Math.max(minPollingInterval, currentPollingInterval))
    }

    timeoutId = setTimeout(poll, currentPollingInterval)

  }

  poll = () => {

    if (paginationList.value.currentPageNumber !== 1) {
      numDetailsOpen = 0
      startPolling()
      return
    }

    isPolling.value = true

    previousPageHash = currentPageHash
    currentPageHash = JSON.stringify(paginationList.value.currentPage)

    if (previousPageHash === null) previousPageHash = currentPageHash

    store.dispatch('pagination/LOAD_PAGE', { listName: props.paginationListName.value, silent: true })
      .catch(() => {
        // just ignore errors during polling
      })
      .finally(() => {
        if (!isPolling.value) return
        startPolling()
      })

  }

  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      wasPollingWhenVisibilityStateHidden = isPolling.value
      stopPolling()
    } else if (wasPollingWhenVisibilityStateHidden) {
      startPolling()
    }
  }

  const detailsToggled = (isOpen) => {

    if (paginationList.value.currentPageNumber !== 1) return

    numDetailsOpen += isOpen ? 1 : -1

    if (numDetailsOpen === 0) {
      startPolling()
    } else {
      stopPolling()
    }

  }

  onBeforeUnmount(() => {
    stopPolling()
    document.removeEventListener('visibilitychange', onVisibilityChange)
  })

  startPolling()
  document.addEventListener('visibilitychange', onVisibilityChange)

  return {
    isPolling,
    stopPolling,
    startPolling,
    paginationList,
    detailsToggled,
  }

}
